import { authAxios as axios } from "shared-auth";
import {
  CheckoutFlow,
  IPurchaseResponse,
  ISubscription,
  ISummary,
  ExistingPaymentMethod,
  PreviewResponse,
} from "../types";
import { endpointsPresets, IEndpointsPreset } from "./billingEndpointsPresets";

export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const FETCH_SUMMARY = "FETCH_SUMMARY";
export const FETCH_ACCESS_TO_CHECKOUT = "FETCH_ACCESS_TO_CHECKOUT";
export const FETCH_EXISTING_PAYMENT_METHOD = "FETCH_EXISTING_PAYMENT_METHOD";

const billingAPIBaseRequestConfig = {
  params: {
    skipGenericErrorHandling: true,
  },
};

interface ChangeSubscriptionPayload {
  planId: string;
  couponCodes: string[];
}

export default class BillingService {
  private readonly url: string;
  private queryAuthParams?: Record<string, any> | undefined;
  private endpointsPreset: IEndpointsPreset;

  constructor(url: string) {
    this.url = url;
    this.endpointsPreset = endpointsPresets[CheckoutFlow.Default](url);
  }

  public setEndPointPreset(presetType: CheckoutFlow) {
    this.endpointsPreset = endpointsPresets[presetType](this.url);
  }

  public setQueryAuthParams(queryParams: Record<string, any> | undefined) {
    this.queryAuthParams = queryParams;
  }

  public async getSubscriptions(planType: string): Promise<ISubscription[]> {
    if (this.queryAuthParams) {
      const {
        onlineApplicationId: id,
        recurlyAccountId: accountId,
        ...qparams
      } = this.queryAuthParams;
      const qs = new URLSearchParams({ id, accountId, planType, ...qparams });
      const request = await fetch(
        `${this.endpointsPreset.subscriptions}?${qs}`
      );

      return await request.json();
    }

    const requestUrl = planType
      ? `${this.endpointsPreset.subscriptions}?planType=${planType}`
      : this.endpointsPreset.subscriptions;

    const { data } = await axios.get(requestUrl);

    return data;
  }

  public async getSummary(): Promise<ISummary> {
    if (this.queryAuthParams) {
      const { onlineApplicationId: id, ...qparams } = this.queryAuthParams;
      const qs = new URLSearchParams({ id, ...qparams });
      const request = await fetch(`${this.endpointsPreset.summary}?${qs}`);

      return await request.json();
    }

    const { data } = await axios.get(this.endpointsPreset.summary);

    return data;
  }

  public async checkoutPreview(previewPayload: {
    planId: string;
    couponCodes: string[];
    addOns: string[];
  }): Promise<PreviewResponse> {
    if (this.queryAuthParams) {
      const { onlineApplicationId: id, ...qparams } = this.queryAuthParams;
      const qs = new URLSearchParams({ id, ...qparams });
      const request = await fetch(`${this.endpointsPreset.preview}?${qs}`, {
        method: "POST",
        body: JSON.stringify(previewPayload),
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      });

      return await request.json();
    }

    const { data } = await axios.post(
      this.endpointsPreset.preview,
      previewPayload,
      billingAPIBaseRequestConfig
    );

    return data;
  }

  public async purchase(data: {
    token: string;
    threeDsToken?: string;
    planId?: string;
    couponCodes?: string[];
    addOns?: string[];
  }): Promise<IPurchaseResponse> {
    if (this.queryAuthParams) {
      const {
        onlineApplicationId: id,
        recurlyAccountId,
        ...qparams
      } = this.queryAuthParams;
      const qs = new URLSearchParams({ id, ...qparams });
      const request = await fetch(
        `${this.endpointsPreset.purchase}/${recurlyAccountId}?${qs}`,
        {
          method: "POST",
          body: JSON.stringify({
            token: data.token,
            threeDsToken: data?.threeDsToken,
          }),
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      return await request.json();
    }

    const { data: result } = await axios.post(
      this.endpointsPreset.purchase,
      data,
      billingAPIBaseRequestConfig
    );

    return result;
  }

  public async getExistingPaymentMethod(): Promise<ExistingPaymentMethod> {
    const { data: result } = await axios.get(
      this.endpointsPreset.existingPaymentMethod,
      billingAPIBaseRequestConfig
    );

    return result;
  }

  public async renewSubscriptionWithExisting(
    data: ChangeSubscriptionPayload
  ): Promise<IPurchaseResponse> {
    const { data: result } = await axios.post(
      this.endpointsPreset.purchase,
      data
    );

    return result;
  }
}

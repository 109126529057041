import React from "react";

import { IGlobalContextProps } from "shared/contexts/GlobalContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";
import { ITranslationContextProps } from "contexts/TranslationContext";
import { ILogContextProps } from "shared/contexts/LoggerContext";
import { IRoxProps } from "contexts/RoxContext";

interface IMaintenanceAlertContent {
  maintenanceAlertTitle: string;
  maintenanceAlertDescription: string;
  maintenanceCloseAlert: string;
  maintenanceGoToMainSiteTitle: string;
  maintenanceGoToMainSiteLink: string;
}
export const IMaintenanceAlertContentKeys = [
  "maintenanceAlertTitle",
  "maintenanceAlertDescription",
  "maintenanceCloseAlert",
  "maintenanceGoToMainSiteTitle",
  "maintenanceGoToMainSiteLink",
];

interface IMaintenanceMessageState {
  isContentHidden: boolean;
}

declare type withMaintenanceMessageProps = IGlobalContextProps &
  ITranslationContextProps &
  ILogContextProps &
  IRoxProps;

export default function withMaintenanceMessage<
  P extends withMaintenanceMessageProps
>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P, IMaintenanceMessageState> {
    public readonly state: Readonly<IMaintenanceMessageState> = {
      isContentHidden: false,
    };

    constructor(props: P) {
      super(props);
      const { roxContainer } = this.props;

      this.state = {
        isContentHidden: roxContainer.isEnabled("maintenanceAlert"),
      };
    }

    public render() {
      return (
        !this.state.isContentHidden && <WrappedComponent {...this.props} />
      );
    }

    public componentDidMount() {
      this.state.isContentHidden && this.showMaintenancePopup();
    }

    private showMaintenancePopup = () => {
      const { globalContext } = this.props;

      const message = this.getMaintenanceAlert();

      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message,
        true
      );
    };

    private getMaintenanceAlert = () => {
      const content =
        this.props.translator.createTranslationObject<IMaintenanceAlertContent>(
          IMaintenanceAlertContentKeys,
          "maintenanceAlert"
        );

      return new AlertConfigBuilder()
        .initBuildEntity()
        .setDefaultContent({
          title: content.maintenanceAlertTitle,
          description: content.maintenanceAlertDescription,
          closeButtonText: content.maintenanceCloseAlert,
        })
        .setCloseButton({
          name: content.maintenanceGoToMainSiteTitle,
          click: () =>
            window.location.replace(content.maintenanceGoToMainSiteLink),
        })
        .build();
    };
  };
}

import React from "react";
import Rox from "rox-browser";

import { Omit } from "shared/utils/types";

export class RoxContainer {
  public localization = Rox ? new Rox.Flag(false) : false;
  public profileCreditsDateFrom = Rox ? new Rox.Flag(false) : false;
  public profileCreditsVenue = Rox ? new Rox.Flag(false) : false;
  public basicInfoVocalAge = Rox ? new Rox.Flag(false) : false;
  public basicInfoPregnancy = Rox ? new Rox.Flag(false) : false;
  public basicInfoDisabilitiesEdit = Rox ? new Rox.Flag(false) : false;
  public basicInfoDisabilitiesView = Rox ? new Rox.Flag(false) : false;
  public basicInfoGender = Rox ? new Rox.Flag(false) : false;
  public maintenanceAlert = Rox ? new Rox.Flag(false) : false;
  public castingDirectorQuickSearch = Rox ? new Rox.Flag(false) : false;
  public selfRepresented = Rox ? new Rox.Flag(false) : false;
  public availabilityCalendar = Rox ? new Rox.Flag(false) : false;
  public seekingRepresentation = Rox ? new Rox.Flag(false) : false;
  public Premium_ProfileStats = Rox ? new Rox.Flag(false) : false;

  public isEnabled<TKey extends keyof RoxContainer>(flagName: TKey) {
    if (flagName in this) {
      if ("boolean" !== typeof this[flagName]) {
        // @ts-ignore
        return this[flagName].isEnabled();
      }
    }
    return false;
  }
}

export interface IRoxProps {
  roxContainer: RoxContainer;
}

export const RoxContext = React.createContext<RoxContainer>(new RoxContainer());

export const RoxConsumer = RoxContext.Consumer;

export function withRoxAccess<
  TKey extends keyof RoxContainer,
  P extends IRoxProps
>(
  Component: React.ComponentClass<P> | React.FC<P>,
  flagName?: TKey
): React.FC<Omit<P, keyof IRoxProps>> {
  return function BoundComponent(props: Omit<P, keyof IRoxProps>) {
    return (
      <RoxConsumer>
        {(value) => {
          const newPropsSet: P = {
            ...props,
            roxContainer: value,
          } as any as P;

          if (flagName) {
            return value.isEnabled(flagName) ? (
              <Component {...newPropsSet} />
            ) : null;
          } else {
            return <Component {...newPropsSet} />;
          }
        }}
      </RoxConsumer>
    );
  };
}

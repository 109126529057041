import React from "react";

import RoxProvider from "./RoxProvider";

export function withRoxProvider<P extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
) {
  return (props: P) => (
    <RoxProvider>
      <WrappedComponent {...props} />
    </RoxProvider>
  );
}

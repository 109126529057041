import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { UserTypes } from "shared-types";
import { useZendesk } from "shared-hooks";
import { authorizationService } from "shared-auth";
import { UserClaimTypes, UserManager, UserRoles } from "app/core";
import { getLocalUrl } from "app/generics";
import { LOG_DOMAIN } from "app/store/logger-dictionary";
import { AddNewAudioPage, AddNewVideoPage } from "mediaModules/media";

import { CommonSection } from "sections/CommonSection";
import { FooterSection } from "sections/FooterSection";
import { HeaderSection } from "sections/HeaderSection";
import { Spinner } from "component-library";
import {
  IGlobalContextProps,
  withGlobalContext,
} from "shared/contexts/GlobalContext";
import { ILogContextProps, withLog } from "shared/contexts/LoggerContext";
import compose from "shared/utils/compose";
import { GlobalEventTypes } from "contexts/GlobalContext";
import { IRoxProps, withRoxAccess } from "contexts/RoxContext";
import { Logout } from "pages/AuthPages";
import {
  IRouteResolverProps,
  ResolveRouteAction,
  RouteResolver,
} from "components/common/RouteResolver";
import { initSentry } from "shared-services";
import {
  MainPage,
  EditCreditPage,
  BasicInfoPage,
  CreditsPage,
  TrainingPage,
  AboutMePage,
  SkillsPage,
  AgenciesPage,
  AddAgencyPage,
  AddNewAgencyPage,
  AgenciesHelpPage,
  PageNotFound,
  SocialMediaPage,
  GalleryPage,
  LinkToProfilePage,
  ProfileNotFoundPage,
  AudioPage,
  VideoPage,
  EditAudioPage,
  EditVideoPage,
} from "./PagesList";
import "./MasterPage.scss";

const setCommonRouteRestrictions = (routes: IRouteResolverProps[]) => {
  routes.map((route) => {
    if (!Boolean(route.rules)) {
      route.rules = [];
    }

    if (!Boolean(route.negativeRouteResolver)) {
      route.negativeRouteResolver = getLocalUrl("404");
    }

    route.rules?.push({
      claim: UserClaimTypes.role,
      action: ResolveRouteAction.deny,
      values: [UserRoles.castingDirector],
    });

    return route;
  });
};

const setTechnicalRoutes = (
  routes: IRouteResolverProps[],
  basicPath: string
) => {
  routes.push(
    {
      path: `${basicPath}404`,
      component: PageNotFound,
    },
    {
      exact: true,
      path: `${basicPath}logout`,
      component: Logout,
    }
  );
};

const wrapInSuspense = (route: IRouteResolverProps): IRouteResolverProps => {
  if (route.component) {
    const Component = route.component;

    route.component = (props) => <Component {...props} />;
  }

  return route;
};

const renderRoutes = (basicPath: string): JSX.Element[] => {
  const routes: IRouteResolverProps[] = [
    {
      exact: true,
      path: `${basicPath}`,
      component: MainPage,
    },
    {
      exact: true,
      path: `${basicPath}photos`,
      component: GalleryPage,
    },
    {
      exact: true,
      path: `${basicPath}credits`,
      component: CreditsPage,
    },
    {
      path: `${basicPath}credits/edit/:creditId?`,
      component: EditCreditPage,
    },
    {
      path: `${basicPath}credits/addcredit`,
      component: EditCreditPage,
    },
    {
      path: `${basicPath}details`,
      component: BasicInfoPage,
    },
    {
      path: `${basicPath}training`,
      component: TrainingPage,
    },
    {
      path: `${basicPath}aboutme`,
      component: AboutMePage,
    },
    {
      path: `${basicPath}skills`,
      component: SkillsPage,
    },
    {
      exact: true,
      path: `${basicPath}contacts`,
      component: AgenciesPage,
      rules: [
        {
          claim: UserClaimTypes.role,
          action: ResolveRouteAction.deny,
          values: [UserRoles.agent],
        },
      ],
    },
    {
      path: `${basicPath}contacts/add`,
      component: AddAgencyPage,
      rules: [
        {
          claim: UserClaimTypes.role,
          action: ResolveRouteAction.deny,
          values: [UserRoles.agent],
        },
      ],
    },
    {
      path: `${basicPath}contacts/new`,
      component: AddNewAgencyPage,
      rules: [
        {
          claim: UserClaimTypes.role,
          action: ResolveRouteAction.deny,
          values: [UserRoles.agent],
        },
      ],
    },
    {
      path: `${basicPath}contacts/help`,
      component: AgenciesHelpPage,
      rules: [
        {
          claim: UserClaimTypes.role,
          action: ResolveRouteAction.deny,
          values: [UserRoles.agent],
        },
        {
          claim: UserClaimTypes.artistType,
          action: ResolveRouteAction.allow,
          values: [
            UserTypes.youngPerformerMale,
            UserTypes.youngPerformerFemale,
          ],
        },
      ],
    },
    {
      path: `${basicPath}links`,
      component: SocialMediaPage,
      rules: [
        {
          claim: UserClaimTypes.role,
          action: ResolveRouteAction.allow,
          values: [UserRoles.spotlight],
        },
      ],
    },
    {
      path: `${basicPath}linktoprofile`,
      component: LinkToProfilePage,
    },
    {
      path: `${basicPath}media/video`,
      exact: true,
      component: VideoPage,
    },
    {
      path: `${basicPath}media/video/new`,
      component: AddNewVideoPage,
    },
    {
      path: `${basicPath}media/video/edit/:id`,
      component: EditVideoPage,
    },
    {
      path: `${basicPath}media/audio`,
      exact: true,
      component: AudioPage,
    },
    {
      path: `${basicPath}media/audio/new`,
      component: AddNewAudioPage,
    },
    {
      path: `${basicPath}media/audio/edit/:id`,
      component: EditAudioPage,
    },
  ];

  setCommonRouteRestrictions(routes);
  setTechnicalRoutes(routes, basicPath);

  const viewPin = UserManager.getClaim(UserClaimTypes.viewPin);
  routes.push({
    exact: true,
    path: `${basicPath}myviewpin`,
    rules: [
      {
        claim: UserClaimTypes.role,
        action: ResolveRouteAction.allow,
        values: [],
      },
    ],
    negativeRouteResolver: viewPin ? `/${viewPin}` : getLocalUrl("404"),
  });

  return routes
    .map((route) => wrapInSuspense(route))
    .map((route, index) => <RouteResolver key={index} {...route} />);
};

export interface IMasterPageProps
  extends RouteComponentProps,
    IGlobalContextProps,
    IRoxProps,
    ILogContextProps {}

const MasterPage: React.FC<IMasterPageProps> = (props) => {
  const menuContainerRef = React.useRef<HTMLDivElement>(null);
  const isAuthenticated = authorizationService.isAuthenticated();
  const { match, globalContext } = props;
  const basicPath = `${match.path}profile/`;

  useZendesk(["details", "contacts", "profile"]);

  useEffect(() => {
    globalContext.notifyListener(
      GlobalEventTypes.makeVisibleGlobalSpinner,
      false
    );

    initSentry(authorizationService.getDecodedUserToken(), "profile-app");

    return () => {
      UserManager.setClaim(UserClaimTypes.userId, "");
    };
  }, []);

  const routes = React.useMemo(() => renderRoutes(basicPath), [basicPath]);

  return (
    <div className="a-master-page">
      <div className="a-master-page__header">
        {isAuthenticated && (
          <HeaderSection menuContainerRef={menuContainerRef} />
        )}
      </div>
      <div className="a-master-page__body">
        <div ref={menuContainerRef} className="a-master-page__menu-container" />
        <div
          id="#content-container"
          className="a-master-page__content-container"
        >
          <div className="a-master-page__content">
            <div className="a-master-page__hidden-panel" />
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                <Route
                  path="/profilenotfound"
                  component={ProfileNotFoundPage}
                />
                <Redirect
                  from={`${basicPath}:viewPin(\\d{4}-\\d{4}-\\d{4})`}
                  to={`${match.path}:viewPin(\\d{4}-\\d{4}-\\d{4})`}
                />
                {routes.map((route) => route)}
                <Route
                  key="viewPin"
                  exact={true}
                  path={`${match.path}:viewPin(\\d{4}-\\d{4}-\\d{4})`}
                  component={MainPage}
                />
                <Redirect to={`${basicPath}404/`} />
              </Switch>
            </React.Suspense>
          </div>
          <div className="a-master-page__footer">
            <FooterSection />
          </div>
        </div>
      </div>
      <CommonSection />
    </div>
  );
};

export default compose(
  withGlobalContext,
  withRoxAccess,
  withRouter,
  withLog(LOG_DOMAIN.masterPage)
)(MasterPage);

/**
 * Exposing the __CONFIG__ object declared in webpack.DefinePlugin to
 * our typescript context so it looks like a module, not a global variable
 */

declare var __CONFIG__: any;

export const config: {
  siteRootUrl: string;
  profileApiUrl: string;
  mediaApiRoot: string;
  cloudinaryApi: string;
  locationApi: string;
  umbracoApiUrl: string;
  searchAndFiltersApi: string;
  searchAndFiltersFrontendUrl: string;
  portalApi: string;
  liteAgentApiRoot: string;
  identityServerRoot: string;
  searchAndFiltersRoot: string;
  profileIdentityClientId: string;
  profileIdentityScopes: string;
  profileFrontEndUrl: string;
  wwwFrontEndUrl: string;
  frontEndUrl: string;
  portalApiLogoutUrl: string;
  shortlistsApi: string;
  mediaViewerUrl: string;
  photoValidation: {
    acceptedFileTypes: string[];
    maxFileSize: number;
    minResolution: {
      width: number;
      height: number;
    };
  };
  googleAnalytics: {
    trackingCode: string;
    debug: boolean;
    gaOptions: {
      name: string;
      cookieDomain: string;
    };
  };
  maxDiscoveryScreensCompletions: number;
  maxDiscoveryScreensCreditsCompletions: number;
  maxDiscoveryScreensBasicInfoCompletions: number;
  maxHighlightedCredits: number;
  braze: {
    apiKey: string;
    baseUrl: string;
  };
  rox: {
    key: string;
    namespace: string;
  };
  isDev?: boolean;
  gtm: {
    gtmId: string;
    dataLayerName: string;
    accountTypes: {
      agent: string;
      performer: string;
      castingDirector: string;
    };
  };
} = {
  ...__CONFIG__,
  ...{
    photoValidation: {
      acceptedFileTypes: ["image/jpeg", "image/png", "image/bmp"],
      maxFileSize: 40 * 1024 * 1024,
      minResolution: {
        width: 500,
        height: 500,
      },
    },
    maxHighlightedCredits: 5,
    maxDiscoveryScreensCompletions: 15,
    maxDiscoveryScreensCreditsCompletions: 15,
    maxDiscoveryScreensBasicInfoCompletions: 15,
  },
};

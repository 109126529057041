import { config } from "config";
import { UserClaimTypes, UserManager } from "core";
import React from "react";
import Rox from "rox-browser";

import { RoxContainer, RoxContext } from "./RoxConsumer";

interface IRoxProviderState {
  isLoaded: boolean;
}

export default class RoxProvider extends React.Component<
  any,
  IRoxProviderState
> {
  private container: RoxContainer = new RoxContainer();
  public readonly state: Readonly<IRoxProviderState> = { isLoaded: false };

  public render() {
    const { isLoaded } = this.state;
    const { children } = this.props;

    return (
      <RoxContext.Provider
        value={
          isLoaded
            ? this.container
            : ({ isEnabled: (t) => false } as RoxContainer)
        }
      >
        {children}
      </RoxContext.Provider>
    );
  }

  public async componentDidMount() {
    // @ts-ignore
    Rox.register(config.rox.namespace, this.container);
    await Rox.setup(config.rox.key);

    Rox.setCustomStringProperty(
      "UserId",
      // @ts-ignore
      UserManager.getClaim(UserClaimTypes.userId)
    );

    this.setState({ isLoaded: true });
  }
}

import React from "react";
import FlipMove from "react-flip-move";

import { IMultiSelectItem } from "../MultiSelectItem";
import { MultiSelectListItem } from "../MultiSelectListItem";

interface IMultiSelectListItemsProps<TValue> {
  selectedItems: IMultiSelectItem<TValue>[];
  hideMoveControls?: boolean;
  moveElementUp: (id: any) => void;
  moveElementDown: (id: any) => void;
  removeItem: (id: any) => void;
}

export function MultiSelectListItems<TValue>(
  props: IMultiSelectListItemsProps<TValue>
) {
  const {
    selectedItems,
    hideMoveControls,
    moveElementUp,
    moveElementDown,
    removeItem,
  } = props;

  let list: JSX.Element | null = null;

  if (selectedItems.length > 0) {
    list = (
      <div className="c-multi-select-list__selected-list">
        <FlipMove appearAnimation={false} leaveAnimation={false}>
          {selectedItems.map((item, index, array) => (
            <MultiSelectListItem
              hideMoveControls={hideMoveControls}
              key={item.value + ""}
              item={item}
              isUpMovable={index !== 0}
              isDownMovable={index !== array.length - 1}
              moveElementDown={moveElementDown}
              moveElementUp={moveElementUp}
              removeItem={removeItem}
            />
          ))}
        </FlipMove>
      </div>
    );
  }

  return list;
}

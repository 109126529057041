import cx from "classnames";
import { GAEvents } from "mediaModules/media/components/GAEvents";
import { ShimmeringStub } from "mediaModules/media/components/ShimmeringStub";
import {
  MediaAsset,
  MediaAssetType,
  TranscodeStatus,
} from "mediaModules/media/models";
import React from "react";
import {
  Checkbox,
  formatTime,
  transformMillisecToSec,
} from "component-library";

import { Overlay, OverlayType } from "components/common/Overlay";

import "./ShowreelThumb.scss";

export interface IShowreelThumbProps {
  showreel: MediaAsset;
  hiddenTitle: string;
  processingTitle: string;
  errorTitle: string;
  checkShowreel: (value: boolean) => void;
  playbackVideo: (showreel: MediaAsset) => void;
  editShowreel: (showreel?: MediaAsset) => void;
  selectedShowreels: MediaAsset[];
  getShowreelElements: (value: HTMLDivElement) => HTMLDivElement[];
}

export class ShowreelThumb extends React.Component<IShowreelThumbProps> {
  private showreelThumbRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  public componentDidUpdate() {
    const { getShowreelElements } = this.props;
    if (this.showreelThumbRef && this.showreelThumbRef.current) {
      getShowreelElements(this.showreelThumbRef.current);
    }
  }

  public render() {
    const {
      showreel: { isMediaObjectLoading },
    } = this.props;

    return isMediaObjectLoading
      ? this.renderLoadingThumbnail()
      : this.renderLoadedThumbnail();
  }

  private renderLoadingThumbnail() {
    const {
      showreel: { title = "" },
    } = this.props;

    return (
      <div
        className="c-showreel-thumb c-showreel-thumb--hover"
        ref={this.showreelThumbRef}
      >
        <div className="c-showreel-thumb__video c-showreel-thumb__video--loading">
          <ShimmeringStub />
        </div>
        <a className="c-showreel-thumb__description" tabIndex={0}>
          <h4 className="c-showreel-thumb__file-name">{title}</h4>
          <p className="c-showreel-thumb__duration" />
        </a>
      </div>
    );
  }

  private renderLoadedThumbnail() {
    const { showreel, playbackVideo, selectedShowreels, editShowreel } =
      this.props;

    const durationString = formatTime(
      transformMillisecToSec(showreel.durationInMs ?? 0),
      ":"
    );

    const backgroundImage = showreel.thumbnails.length
      ? showreel.thumbnails[showreel.selectedThumbnail]
      : "";

    const isProcessing =
      showreel.transcodeStatus === TranscodeStatus.Processing ||
      showreel.transcodeStatus === TranscodeStatus.Awaiting;
    const isError = showreel.transcodeStatus === TranscodeStatus.Error;

    const isSelected = selectedShowreels.some(
      (item) => item.id === showreel.id
    );

    const handleDescriptionClick = () => {
      GAEvents.editMediaAssets.trackEditFromTitleClick(MediaAssetType.Video);
      !isProcessing && !isError && editShowreel(showreel);
    };

    return (
      <div
        className="c-showreel-thumb c-showreel-thumb--hover"
        ref={this.showreelThumbRef}
      >
        <div
          className={cx("c-showreel-thumb__video", {
            "c-showreel-thumb__video--pointer": !isProcessing && !isError,
          })}
          style={{ backgroundImage: `url(${backgroundImage})` }}
          onClick={() => playbackVideo(showreel)}
          aria-label="Play"
        >
          {this.renderOverlay()}
        </div>
        <a
          className={cx("c-showreel-thumb__description", {
            "c-showreel-thumb__description--disabled": isProcessing || isError,
          })}
          onClick={handleDescriptionClick}
          role="anchor"
          tabIndex={0}
        >
          <h4 className="c-showreel-thumb__file-name">{showreel.title}</h4>
          <p className="c-showreel-thumb__duration">{durationString}</p>
        </a>
        <Checkbox
          htmlId="showreelThumbCheckbox"
          name={showreel.title}
          ariaLabel={showreel.title}
          valueChanged={this.props.checkShowreel}
          value={showreel.title}
          checked={isSelected}
          className="c-showreel-thumb__checkbox"
        />
      </div>
    );
  }

  private renderOverlay() {
    const { showreel, hiddenTitle, processingTitle, errorTitle } = this.props;

    const isProcessing =
      showreel.transcodeStatus === TranscodeStatus.Processing ||
      showreel.transcodeStatus === TranscodeStatus.Awaiting;
    const isError = showreel.transcodeStatus === TranscodeStatus.Error;
    const { visible } = showreel;

    return isProcessing || isError || !visible ? (
      <Overlay
        title={
          isProcessing ? processingTitle : isError ? errorTitle : hiddenTitle
        }
        icon={
          isProcessing
            ? "icon-processing"
            : isError
            ? "icon-alarm"
            : "icon-hide"
        }
        overlayType={isProcessing ? OverlayType.Light : OverlayType.Dark}
      />
    ) : (
      <div className="c-showreel-thumb__play-icon icon-playsmall" />
    );
  }
}

import React from "react";
import { withRouter } from "react-router-dom";

import { withGlobalContextProvider } from "shared/contexts/GlobalContext";
import { withLogHub } from "shared/contexts/LoggerContext";
import compose from "shared/utils/compose";
import { parseQuery } from "shared/utils/http";

import { withChangesTracker } from "contexts/ChangesTrackingContext";
import { withFlowContextProvider } from "contexts/FlowContext";
import { withOidcAuthContext } from "contexts/OidcAuthContext";
import { withRoxAccess, withRoxProvider } from "contexts/RoxContext";

import { getApiUrl } from "services/UrlBuilder";

import { withTranslation as withTranslationConsumer } from "./contexts/TranslationContext";
import { UserManager, UserRoleManager } from "./core";
import withAnonymousAccess from "./hoc/withAnonymousAccess";
import withErrorCatcher from "./hoc/withErrorCatcher";
import withGlobalLoader from "./hoc/withGlobalLoader";
import withGoogleAnalytics from "./hoc/withGoogleAnalytics";
import withIdentification from "./hoc/withIdentification";
import withMaintenanceMessage from "./hoc/withMaintenanceMessage";
import withOidcAuthentication from "./hoc/withOIdcAuthentication";
import withPageData from "./hoc/withPageData";
import withQueryActionHandling from "./hoc/withQueryActionHandling";
import withTranslation from "./hoc/withTranslation";
import withUserInfo from "./hoc/withUserInfo";
import MasterPage from "./MasterPage/MasterPage";

import "./App.scss";

export const App: React.FC = () => <MasterPage />;

const LOG_HUB_SETTINGS = {
  domain: "SYSTEM",
  url: getApiUrl("/useractivities"),
  userId: () => {
    let user = "";
    if (UserManager.userId) {
      user = UserManager.userId;
    } else {
      const searchObject = parseQuery(location.search);
      user = searchObject.artistref;
    }

    return user;
  },
  userInfo: () => ({
    userRole: UserRoleManager.getUserRole(),
  }),
};

export default compose(
  withLogHub(LOG_HUB_SETTINGS),
  withOidcAuthContext,
  withChangesTracker,
  withRouter,
  withGlobalContextProvider,
  withGlobalLoader,
  withTranslation,
  withTranslationConsumer,
  withErrorCatcher,
  withAnonymousAccess,
  withIdentification,
  withOidcAuthentication,
  withUserInfo,
  withRoxProvider,
  withRoxAccess,
  withMaintenanceMessage,
  withFlowContextProvider,
  withGoogleAnalytics,
  withPageData,
  withQueryActionHandling
)(App);

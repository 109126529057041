import React from "react";
import { IVideoModel } from "shared-types";
import cx from "classnames";
import Hls from "hls.js";
import { MediaPlayer, MediaPlayerClass } from "dashjs";

import { transformMillisecToSec } from "../../../../../generics/time-formatting";
import { isIOS } from "../../../../../generics/os-detection";
import { isSafari } from "../../../../../generics/browser-detection";
import { IShareVideoPopupTexts } from "../../VideoPlayer";
import { VideoControls } from "../VideoControls/VideoControls";
import { ReactComponent as CloseIcon } from "../../../../../images/svg/icons/circle-close.svg";
import { Popup } from "../../../../Molecules/Popup/Popup";
import {
  AlertMessage,
  IAlertMessageProps,
} from "../../../../Molecules/AlertMessage/AlertMessage";
import { Input } from "../../../../Molecules/Input/Input";
import { Button } from "../../../../Atoms/Button/Button";

import "./Video.scss";

const RATIO = 1000;

export const MAX_VOLUME_LEVEL = 1;
export const MIN_VOLUME_LEVEL = 0;
export const SOURCE_TYPES = {
  M3U8: "m3u8",
  MPD: "mpd",
  VIDEO: "video",
};

export const VIDEO_EXTENSIONS = /\.(mp4|og[gv]|webm|mov|m4v)($|\?)/i;
export const HLS_EXTENSIONS = /\.(m3u8)($|\?)/i;
export const DASH_EXTENSIONS = /\.(mpd)($|\?)/i;

export interface IVideoProps {
  video: IVideoModel;
  autoPlay: boolean;
  switchToNextVideo: () => void;
  hasCloseButton: boolean;
  hasDownloadButton: boolean;
  hasShareVideoButton: boolean;
  showreelMode: boolean;
  shareVideoPopupTexts?: IShareVideoPopupTexts;
  closePlayer?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  titlePrefix?: string;
}

export interface IVideoState {
  currentTime: number;
  isMuted: boolean;
  currentMediaType: string;
  isLoading: boolean;
  volumeLevel: number;
  isShareVideoPopupVisible: boolean;
}

export class Video extends React.Component<IVideoProps, IVideoState> {
  private videoRef = React.createRef<HTMLVideoElement>();
  private hls: Hls;
  private dash: MediaPlayerClass;
  private isIOS: boolean = isIOS();
  private isSafari: boolean = isSafari();
  private isSafariCanPlay: boolean = false;
  private previousVolumeLevel: number;

  public readonly state: Readonly<IVideoState> = {
    currentTime: 0,
    isMuted: false,
    currentMediaType: "",
    isLoading: true,
    volumeLevel: MAX_VOLUME_LEVEL,
    isShareVideoPopupVisible: false,
  };

  public componentDidMount() {
    this.initPlayer(this.props.video.sources);
  }

  public componentDidUpdate(prevProps: IVideoProps) {
    if (prevProps.video.id !== this.props.video.id) {
      this.updatePlayerSource();
    }
  }

  public render() {
    const {
      video: { downloadLink, shareLink },
      switchToNextVideo,
      hasDownloadButton,
      hasShareVideoButton,
      shareVideoPopupTexts,
      showreelMode,
    } = this.props;
    const videoElem = this.videoRef.current;
    const {
      isLoading,
      isMuted,
      currentTime,
      volumeLevel,
      isShareVideoPopupVisible,
    } = this.state;
    const isShareVideoButtonAvailable = Boolean(
      hasShareVideoButton && shareLink && shareVideoPopupTexts
    );

    return (
      <div className="c-video">
        {this.renderTopBar()}
        {this.renderVideoSection()}
        <VideoControls
          togglePlayback={this.togglePlayback}
          expandVideo={this.expandVideo}
          changeTime={this.changingBarPosition}
          toggleMute={this.toggleMute}
          switchToNextVideo={switchToNextVideo}
          shareVideo={this.shareVideo}
          duration={
            videoElem?.duration
              ? transformMillisecToSec(videoElem?.duration * RATIO)
              : 0
          }
          isPaused={videoElem ? videoElem.paused : true}
          isMuted={isMuted}
          hasDownloadButton={hasDownloadButton}
          hasShareVideoButton={isShareVideoButtonAvailable}
          currentTime={transformMillisecToSec(currentTime)}
          downloadLink={downloadLink}
          showreelMode={showreelMode}
          isLoading={isLoading}
          adjustVolume={this.adjustVolume}
          setPreviousVolumeLevel={this.setPreviousVolumeLevel}
          volumeLevel={volumeLevel}
          requestPIP={this.requestPIP}
        />
        {isShareVideoButtonAvailable &&
          isShareVideoPopupVisible &&
          this.renderShareVideoPopup()}
      </div>
    );
  }

  private renderTopBar = () => {
    const { video, hasCloseButton, closePlayer, titlePrefix } = this.props;
    const { isLoading } = this.state;
    const videoElem = this.videoRef.current;
    const isPaused = videoElem ? videoElem.paused : true;

    const prefix = titlePrefix ? titlePrefix + " | " : "";
    const pauseClassName = isPaused ? "icon-playsmall" : "is-hidden";
    const disabledClassName = isLoading ? "c-video__btn-disabled" : "";

    const blurElement = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => event.currentTarget.blur();

    return (
      <React.Fragment>
        {video && (
          <div className="c-video__file-name">
            <span className="c-video__title-prefix">{prefix}</span>
            {video.fileName}
          </div>
        )}

        {hasCloseButton && (
          <button
            type="button"
            onClick={closePlayer}
            className="c-video__btn c-video__close-btn"
            aria-label="Close video"
          >
            <CloseIcon width={40} height={40} />
          </button>
        )}
        <button
          type="button"
          onMouseUp={blurElement}
          onClick={this.togglePlayback}
          className={cx(
            "c-video__btn",
            "c-video__cover-pause-btn",
            pauseClassName,
            disabledClassName
          )}
          aria-label="Toggle play video"
        />
      </React.Fragment>
    );
  };

  private renderVideoSection = () => {
    const { video, autoPlay, onPlay, onPause } = this.props;
    const { isMuted } = this.state;
    const controlsList = "nodownload";

    return (
      video && (
        <video
          className="c-video__video"
          ref={this.videoRef}
          onTimeUpdate={this.currentTimeUpdating}
          autoPlay={autoPlay}
          onClick={this.togglePlayback}
          muted={isMuted}
          onPlay={onPlay}
          onPause={onPause}
          poster={video.poster}
          onLoadStart={this.onLoadStart}
          onLoadedMetadata={this.onLoadedMetadata}
          onCanPlay={this.onCanPlay}
          controlsList={controlsList}
        />
      )
    );
  };

  private renderShareVideoPopup = () => {
    const {
      shareVideoPopupTexts,
      video: { shareLink = "" },
    } = this.props;
    const shareVideoPopupProps: IAlertMessageProps = {
      texts: {
        title: shareVideoPopupTexts!.title,
      },
      buttons: [
        {
          name: shareVideoPopupTexts!.proceedButton,
          type: "primary",
          click: this.handleCopyLink,
        },
        {
          name: shareVideoPopupTexts!.closeButton,
          type: "secondary",
          click: this.handleCloseSharePopup,
        },
      ],
    };

    return (
      <Popup
        width={{ lg: 5, md: 6 }}
        close={this.handleCloseSharePopup}
        priority="high"
        texts={{ closePopup: shareVideoPopupTexts!.closeButton }}
      >
        <AlertMessage {...shareVideoPopupProps}>
          <p className="c-video__main-share-popup-description">
            {shareVideoPopupTexts!.mainDescription}
          </p>
          <div className="c-video__share-link-wrapper">
            <Input
              type="text"
              className="c-video__copy-share-link-input"
              value={shareLink}
            />
            <Button
              type="primary"
              className="c-video__copy-share-link-button"
              text=""
              iconName="icon-duplicate"
              onClick={this.handleCopyLink}
            />
          </div>
          <p className="c-video__additional-share-popup-description">
            {shareVideoPopupTexts!.additionalDescriptions}
          </p>
        </AlertMessage>
      </Popup>
    );
  };

  private onLoadStart = () => {
    this.isSafariCanPlay = false;
    this.setState({ isLoading: true });
  };

  private onLoadedMetadata = () => {
    if (this.isSafari) {
      this.setState({ isLoading: false });
    }
  };

  private onCanPlay = () => {
    if (this.isSafari && !this.isSafariCanPlay) {
      this.isSafariCanPlay = true;
      this.setState({ isLoading: false }, async () => {
        const videoElement = this.videoRef.current!;
        await videoElement.pause();
        await this.togglePlayback();
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  private requestPIP = () => {
    const { current: video } = this.videoRef;

    if (video && video.requestPictureInPicture) {
      video.requestPictureInPicture();
    }
  };

  private togglePlayback = async () => {
    const videoElement = this.videoRef.current;

    if (videoElement) {
      if (this.isSafari && !this.isSafariCanPlay) {
        // tmp workaround to initiate onCanPlay event on Safari
        return this.setState({ isLoading: true }, () => videoElement.play());
      }

      if (!this.state.isLoading) {
        try {
          videoElement.paused ? videoElement.play() : videoElement.pause();
        } catch (error) {
          console.error("Error is happened during toggling playback", error);
        }
      }
    }
  };

  private expandVideo = async () => {
    const videoElement = this.videoRef.current;
    if (videoElement) {
      try {
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if (videoElement.webkitEnterFullscreen) {
          videoElement.webkitEnterFullscreen();
        }
      } catch (error) {
        console.error("Error is happened during requesting full screen", error);
      }
    }
  };

  private currentTimeUpdating = () => {
    const videoElement = this.videoRef.current;
    if (videoElement) {
      this.setState({ currentTime: videoElement.currentTime * RATIO });
    }
  };

  private changingBarPosition = (time: number) => {
    const videoElement = this.videoRef.current;

    if (videoElement) {
      videoElement.currentTime = time;
      this.setState({ currentTime: time * RATIO });
    }
  };

  private shareVideo = () => {
    this.setState({ isShareVideoPopupVisible: true });
  };

  private handleCopyLink = async () => {
    const {
      video: { shareLink },
    } = this.props;

    try {
      navigator.clipboard.writeText(shareLink!);
    } catch (error) {
      console.error("Error is happened during copying the share link", error);
    }
  };

  private handleCloseSharePopup = () => {
    this.setState({ isShareVideoPopupVisible: false });
  };

  private toggleMute = () => {
    const videoElement = this.videoRef.current;

    if (videoElement) {
      this.setState({
        isMuted: !this.state.isMuted,
      });

      this.state.isMuted
        ? this.setState({
            volumeLevel: videoElement.volume,
          })
        : this.setState({
            volumeLevel: MIN_VOLUME_LEVEL,
          });

      if (videoElement.volume === MIN_VOLUME_LEVEL) {
        this.setVolume(this.previousVolumeLevel);
      }
    }
  };

  private adjustVolume = (value: number) => {
    const videoElement = this.videoRef.current;

    if (videoElement) {
      this.setVolume(value);

      if (value === MIN_VOLUME_LEVEL) {
        this.setState({
          isMuted: true,
        });
      }

      if (value > MIN_VOLUME_LEVEL) {
        this.setState({
          isMuted: false,
        });
      }
    }
  };

  private setPreviousVolumeLevel = () => {
    const videoElement = this.videoRef.current;

    if (videoElement) {
      this.previousVolumeLevel = videoElement.volume;
    }
  };

  private setVolume = (value: number) => {
    const videoElement = this.videoRef.current;

    videoElement!.volume = value;

    this.setState({ volumeLevel: videoElement!.volume });
  };

  private initPlayer = (sources) => {
    const source = this.getSupportedSource(sources);

    if (source) {
      const { url, type: currentMediaType } = source;
      this.setState({ currentMediaType }, () => this.choosePlayer(url));
    }
  };

  private choosePlayer = (url) => {
    const videoElement = this.videoRef.current!;
    const { autoPlay } = this.props;

    switch (this.state.currentMediaType) {
      case SOURCE_TYPES.M3U8: {
        if (this.isIOS) {
          this.initNativePlayer(videoElement, url, autoPlay);
        } else {
          this.initHlsPlayer(videoElement, url, autoPlay);
        }
        break;
      }
      case SOURCE_TYPES.MPD: {
        this.initDashPlayer(videoElement, url, autoPlay);
        break;
      }
      case SOURCE_TYPES.VIDEO: {
        this.initNativePlayer(videoElement, url, autoPlay);
        break;
      }
    }
  };

  private initHlsPlayer = (videoElement, url, autoPlay = false) => {
    this.hls = new Hls();
    this.hls.loadSource(url);
    this.hls.attachMedia(videoElement);
    this.hls.on(
      Hls.Events.MANIFEST_PARSED,
      () => autoPlay && videoElement.play()
    );
  };

  private initDashPlayer = (videoElement, url, autoPlay = false) => {
    this.dash = MediaPlayer().create();
    this.dash.initialize(videoElement, url, autoPlay);
  };

  private initNativePlayer = (videoElement, url, autoPlay = false) => {
    videoElement.src = url;
    videoElement.autoplay = autoPlay;
  };

  private destroyHlsPlayer = () => {
    this.hls.destroy();
  };

  private destroyDashPlayer = () => {
    this.dash.reset();
  };

  private destroyNativePlayer = () => {
    this.videoRef.current!.src = "";
  };

  private updatePlayerSource = () => {
    switch (this.state.currentMediaType) {
      case SOURCE_TYPES.M3U8: {
        if (this.isIOS) {
          this.destroyNativePlayer();
        } else {
          this.destroyHlsPlayer();
        }
        break;
      }
      case SOURCE_TYPES.MPD: {
        this.destroyDashPlayer();
        break;
      }
      case SOURCE_TYPES.VIDEO: {
        this.destroyNativePlayer();
        break;
      }
    }

    this.initPlayer(this.props.video.sources);
  };

  private getSupportedSource = (sources: string[]) => {
    const hlsManifest = sources.find((src) => src.match(HLS_EXTENSIONS));
    if (hlsManifest) {
      return { url: hlsManifest, type: SOURCE_TYPES.M3U8 };
    }

    const mpdManifest = sources.find((src) => src.match(DASH_EXTENSIONS));
    if (mpdManifest) {
      return { url: mpdManifest, type: SOURCE_TYPES.MPD };
    }

    const videoUrl = sources.find((src) => src.match(VIDEO_EXTENSIONS));
    if (videoUrl) {
      return { url: videoUrl || "", type: SOURCE_TYPES.VIDEO };
    }

    return null;
  };
}
